import { Box, Button, Flex } from "@jewlr/storybook/core"
import { FocusScope } from "@react-aria/focus"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import skuColor from "helpers/product/sku-colors"
import sortLooks from "helpers/product/sort-looks"
import { useNavigation } from "helpers/use-navigation"

const LooksContainer = styled(Box)`
  min-height: 19px;
  padding-bottom: 16px;
  padding-top: 8px;
  ${(props) => props.theme.mediaQueries.desktop`
    padding-bottom: 20px;
    padding-top: 20px;
  `}
`

const LookButton = styled(Button).attrs((props) => ({
  bg: "grey.20",
  height: "19px",
  margin: "0 4px",
  variant: "text",
  width: "19px",
  ...props.theme.collectionLooksSelector,
  ...props,
}))`
  border-radius: 50%;
  ${(props) =>
    props.active &&
    `
    border: ${
      props.theme.collectionLooksSelector?.border ||
      `2px solid ${props.border || props.theme.colors.white}`
    };
    box-shadow: 0 0 0 1px ${
      props.theme.collectionLooksSelector?.boxShadow ||
      props.theme.colors.grey35
    };
  `}
  ${(props) =>
    props.vermeil &&
    `
    background-color: ${props.theme.colors.grey[20]};
    border: 4px solid #F8E7AD;
  `}
`

const LookColor = ({ active, handleClick, look, ...props }) => {
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <LookButton
      active={active}
      aria-label={skuColor(look)}
      aria-selected={active}
      bg={`skus.${look.replace(/[0-9]/g, "")}`}
      onClick={handleClick}
      role="option"
      tabIndex={active ? 0 : -1}
      vermeil={["sskyp", "ss18kyp", "vermy"].includes(look)}
      {...props}
      {...keyboardProps}
    />
  )
}

LookColor.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func,
  look: PropTypes.string,
}

const LooksSelector = ({
  border,
  looks,
  product,
  selectedLook,
  updateLook,
}) => {
  return (
    <LooksContainer>
      {Object.keys(looks || {}).length > 0 && (
        <FocusScope>
          <Flex
            aria-label="Metal options"
            aria-orientation="horizontal"
            role="listbox"
          >
            {sortLooks(Object.keys(looks)).map((look) => (
              <LookColor
                active={selectedLook === look}
                handleClick={(e) => {
                  e.preventDefault()
                  updateLook(look)
                }}
                key={`look-${look}-${product.style_code}`}
                look={look}
                {...(border && { border })}
              />
            ))}
          </Flex>
        </FocusScope>
      )}
    </LooksContainer>
  )
}

LooksSelector.propTypes = {
  border: PropTypes.string,
  looks: PropTypes.object.isRequired,
  product: PropTypes.object,
  selectedLook: PropTypes.string,
  updateLook: PropTypes.func,
}

export default LooksSelector
