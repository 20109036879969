import { Expand } from "@jewlr/storybook/animate"
import { Box, Button } from "@jewlr/storybook/core"
import { useId } from "@react-aria/utils"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import ProductCard from "areas/landing/elements/landing-product/product-card"

const Grid = styled(Box).attrs((props) => ({
  display: "grid",
  gridGap: "24px 8px",
  gridTemplateColumns: "repeat(2, 1fr)",
  overflow: "hidden",
  ...props,
}))``

const ViewMore = styled(Button).attrs((props) => ({
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "1.2",
  padding: 1,
  variant: "text",
  ...props,
}))``

const ShopTheLookExpand = ({ element, products }) => {
  const [expand, setExpand] = useState(false)
  const expandId = useId()

  return (
    <>
      <Grid>
        {products.slice(0, 4).map((product, i) => (
          <ProductCard
            element={element}
            index={i}
            key={`${product.style_code}-${i}`}
            product={product}
          />
        ))}
      </Grid>
      <Expand id={expandId} mt={3} show={expand}>
        <Grid>
          {products.slice(4).map((product, i) => (
            <ProductCard
              element={element}
              index={i + 4}
              key={`${product.style_code}-${i}`}
              product={product}
            />
          ))}
        </Grid>
      </Expand>
      <Box mt={2} mx="auto" textAlign="center">
        <ViewMore
          aria-controls={expandId}
          aria-expanded={expand}
          onClick={() => setExpand(!expand)}
        >
          View {expand ? "Less" : "More"}
        </ViewMore>
      </Box>
    </>
  )
}

ShopTheLookExpand.propTypes = {
  element: PropTypes.object,
  products: PropTypes.array,
}

export default ShopTheLookExpand
