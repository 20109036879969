import { Box, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled, { keyframes } from "styled-components"

const shimmer = keyframes`
  from {
    background-position: -256px 0;
  }
  to {
    background-position: 256px 0;
  }
`

const StyledImage = styled(Image).attrs((props) => ({
  ratio: "auto 1 / 1",
  width: "100%",
  ...props,
}))`
  height: auto;
  ${(props) =>
    props.loaded &&
    `
    min-height: 50px;
  `}
  ${(props) =>
    props.enlargeImage &&
    `
    transform: scale(1.25);
  `}
  &.lazyloading {
    animation: ${shimmer} 1.5s forwards infinite;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    overflow: hidden;
  }
`

const ImageContainer = styled(Box)`
  margin: 0 auto;
  ${(props) =>
    props.enlargeImage &&
    `
    overflow: hidden;
  `}
`

const ProductImage = ({
  alt,
  enlargeImage,
  images,
  inLanding,
  index,
  sizes,
  styleCode,
  type,
}) => {
  const [hover, setHover] = useState(false)

  const mainImg = images[0]
  const hovImg = images[1]
  const productImage = hover ? hovImg : mainImg
  const placeholder = getPlaceholderData(type)

  return (
    <ImageContainer enlargeImage={enlargeImage} inLanding={inLanding}>
      <StyledImage
        alt={alt}
        data-cy={`collection-product-img-${styleCode}`}
        enlargeImage={enlargeImage}
        fallback={require(`images/collections/placeholder_masks/${
          placeholder[0]
        }_${(index % placeholder[1]) + 1}.svg`)}
        onMouseEnter={() => !inLanding && images[1] && setHover(true)}
        onMouseLeave={() => !inLanding && setHover(false)}
        sizes={sizes || "(max-width: 1279px) 256px, (min-width: 1280px) 300px"}
        srcSet={productImage}
      />
    </ImageContainer>
  )
}

ProductImage.propTypes = {
  alt: PropTypes.string,
  enlargeImage: PropTypes.bool,
  images: PropTypes.array,
  inLanding: PropTypes.bool,
  index: PropTypes.number,
  sizes: PropTypes.string,
  styleCode: PropTypes.string,
  type: PropTypes.string,
}

export const getPlaceholderData = (type) => {
  switch (type) {
    case "ring":
      return ["ring", 6]
    case "jlux":
      return ["jlux", 6]
    case "earring":
      return ["earring", 2]
    case "bracelet":
      return ["bracelet", 1]
    case "pendant":
    case "necklace":
    case "nameplate":
      return ["pendant", 1]
    default:
      return ["ring", 6]
  }
}

export default ProductImage
