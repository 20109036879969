import { faDiamond } from "@jewlr/sharp-solid-svg-icons/faDiamond"
import { Box, Flex, FontAwesomeIcon, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { to_currency } from "helpers/number"

import LooksSelector from "./looks-selector"
import ProductImage from "./product-image"

const setDefaultLook = (product) => {
  if (!product.looks) return ""
  return product.looks && product.default_look
    ? product.default_look
    : Object.keys(product.looks)[0]
}

const Badge = styled(Flex)`
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  left: 0;
  line-height: 1;
  padding: 8px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
`

const ProductCard = ({ element, index, product }) => {
  const [selectedLook, setSelectedLook] = useState(setDefaultLook(product))

  const selectedProductLook = product.looks ? product.looks[selectedLook] : null

  if (!selectedProductLook) {
    // Prevents selectedLook from being undefined and breaking the component on reload of products. Gives useEffect a chance to update.
    return null
  }

  const badge = product.badges && product.badges[0]

  useEffect(() => {
    // Update selected metal when default_look is changed
    setSelectedLook(setDefaultLook(product))
  }, [product])

  return (
    <Box>
      <Box
        aria-label={product.title || product.name}
        as={Link}
        data-lc={element.anchor_name || element.id}
        display="block"
        position="relative"
        to={selectedProductLook.product_url}
      >
        {badge && (
          <Badge>
            <FontAwesomeIcon
              color="primary"
              fontSize="12px"
              icon={faDiamond}
              mr="4px"
            />
            <Typography color={badge.label_color || "black"}>
              {badge.label}
            </Typography>
          </Badge>
        )}
        <ProductImage
          alt={product.title || product.name}
          images={selectedProductLook.images}
          inLanding
          index={index}
          sizes="(max-width: 767px) 50vw,
                   (max-width: 1023px) 23vw,
                   (max-width: 1920px) calc((100vw - 320px) / 4.5),
                   322px"
          styleCode={product.stlye_code}
          type={product.product_type}
        />
      </Box>
      <Box {...element.style?.item_style?.textarea_style}>
        <LooksSelector
          looks={product.looks}
          product={product}
          selectedLook={selectedLook}
          updateLook={(look) => setSelectedLook(look)}
        />
        <Typography
          display={{ _: "none", desktop: "block" }}
          mb={1}
          variant="body"
        >
          {product.title || product.name}
        </Typography>
        <Typography
          color="pinkTourmaline"
          fontWeight="600"
          mb="4px"
          variant="body-xs"
        >
          SAVE {selectedProductLook.sale_percent}% (
          {to_currency(selectedProductLook.retail)} RETAIL)
        </Typography>
        <Typography fontWeight="600" variant="body-sm">
          FROM {to_currency(selectedProductLook.selling)}
        </Typography>
      </Box>
    </Box>
  )
}

ProductCard.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number,
  product: PropTypes.object,
}

export default ProductCard
