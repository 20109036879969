import { faArrowRight } from "@jewlr/pro-solid-svg-icons/faArrowRight"
import {
  Box,
  Flex,
  FontAwesomeIcon,
  MediaBox,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { StyledArrowImage } from "areas/landing/elements/components"
import ProductCard from "areas/landing/elements/landing-product/product-card"
import Slider from "dependencies/carousel"

import ShopTheLookExpand from "./expand"

const Container = styled(Flex).attrs((props) => ({
  alignItems: { desktop: "center" },
  containWide: true,
  flexDirection: { _: "column", desktop: "row" },
  pl: 2,
  pr: { _: 2, tablet: 0 },
  ...props,
}))``

const StyledSlider = styled(Slider)`
  .slider-frame {
    mask-image: linear-gradient(
      to right,
      hsl(0 0% 0% / 100%) 90%,
      hsl(0 0% 0% / 0)
    );
  }
`

const ShopTheLookCarousel = ({ element }) => {
  const {
    data: { carousel_name, heading, products },
  } = element || { data: {} }

  const sliderSettings = {
    cellSpacing: "40",
    defaultControlsConfig: {
      nextButtonText: (
        <StyledArrowImage
          alt="Next"
          height="30px"
          src={require("images/icons/arrow_bg_light.svg")}
          width="30px"
        />
      ),
      prevButtonText: (
        <StyledArrowImage
          alt="Previous"
          height="30px"
          src={require("images/icons/arrow_bg_light.svg")}
          width="30px"
        />
      ),
    },
    frameAriaLabel: carousel_name,
    slidesToScroll: 1,
    slidesToShow: 4.5,
  }

  return (
    <Box py={{ _: 3, desktop: 5 }} {...element.style?.block_style}>
      <Container {...element.container_style}>
        <Box
          flex={{ desktop: "0 0 160px" }}
          mb={{ _: 1, desktop: 0 }}
          mx={{ desktop: 4, widescreen: "60px" }}
        >
          <Typography variant="h2">
            {heading || (
              <>
                Shop <Box as="br" display={{ _: "none", desktop: "block" }} />
                the look
              </>
            )}{" "}
            <FontAwesomeIcon
              fontSize="16px"
              icon={faArrowRight}
              style={{ verticalAlign: "baseline" }}
            />
          </Typography>
        </Box>
        <MediaBox lessThan="tablet">
          <ShopTheLookExpand element={element} products={products} />
        </MediaBox>
        <MediaBox greaterThanOrEqual="tablet" overflow="hidden">
          <StyledSlider {...sliderSettings}>
            {products.map((product, i) => (
              <ProductCard
                element={element}
                index={i}
                key={`${product.style_code}-${i}`}
                product={product}
              />
            ))}
          </StyledSlider>
        </MediaBox>
      </Container>
    </Box>
  )
}

ShopTheLookCarousel.propTypes = {
  element: PropTypes.object,
}

export default ShopTheLookCarousel
